<template>
  <div class="space-y-4">
    <asom-alert variant="error" v-if="error" :error-message="error" />
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-card v-else title=" ">
      <template v-slot:header>
        <div class="flex space-x-2">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            CP Update Details
          </h3>
          <asom-badge :variant="acknowledgementStatusVariant">{{
            acknowledgementStatusText
          }}</asom-badge>
        </div>
      </template>
      <div class="grid sm:grid-cols-1 md:grid-cols-2">
        <asom-form-field label="CP Update Number">
          <p class="font-semibold">
            {{ get(itemData, "cpUpdateNo", "-") }}
          </p>
        </asom-form-field>
        <asom-form-field label="Title">
          <p class="font-semibold">
            {{ get(itemData, "title", "-") }}
          </p>
        </asom-form-field>
        <asom-form-field
          v-if="get(itemData, 'isAcknowledged', false)"
          label="Date Of Acknowledgement"
        >
          <p class="font-semibold">
            {{ formatDate(get(itemData, "dateOfAckNowledgement", "-")) }}
          </p>
        </asom-form-field>
        <asom-form-field label="Acknowledgement Deadline Date">
          <p class="font-semibold">
            {{ formatDate(get(itemData, "acknowledgementDeadlineDate", "-")) }}
          </p>
        </asom-form-field>
        <asom-form-field label="Created By">
          <p class="font-semibold">
            {{ get(itemData, "createdBy", "-") }}
          </p>
        </asom-form-field>
        <asom-form-field label="Expiry Date">
          <p class="font-semibold">
            {{ formatDate(get(itemData, "expiryDate", "-")) }}
          </p>
        </asom-form-field>
        <asom-form-field class="col-span-2" label="Description">
          <p class="font-semibold">
            {{ get(itemData, "description", "-") }}
          </p>
        </asom-form-field>
        <asom-form-field
          class="col-span-2"
          v-if="itemData.files.length > 0"
          label="Supporting Document"
        >
          <asom-upload-file-list :files="itemData.files" disabled-remove />
        </asom-form-field>
      </div>
      <div class="col-span-2 flex justify-end space-x-4 pt-8">
        <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
        <asom-button
          v-if="!isAcknowledged"
          size="sm"
          outline
          text="Acknowledge"
          @click="confirmAcknowledgement"
        />
      </div>
    </asom-card>

    <asom-modal
      title="Acknowledge CP Update"
      v-model="showAcknowledgeModal"
      :dismissible="false"
    >
      <asom-alert
        variant="error"
        v-if="acknowledgeModalError"
        :error-message="acknowledgeModalError"
      />
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-8">
        <div class="pl-4">
          <asom-button
            @click="onAcknowledge"
            text="Acknowledge"
            :loading="isSubmittingAcknowledge"
          />
        </div>
        <div>
          <asom-button
            @click="toggleAcknowledgeModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import {
  getAcknowledgementDetails,
  acknowledgeCPUpdate,
} from "../../../services/opsComms.service";
import moment from "moment";

export default {
  name: "ViewOrder",
  data() {
    return {
      itemData: {
        cpUpdateId: null,
        cpUpdateNo: get(this.$route.params, "cpUpdateNo", null),
        isAcknowledged: get(this.$route.params, "isAcknowledged", null),
        dateOfAckNowledgement: get(
          this.$route.params,
          "dateOfAckNowledgement",
          null
        ),

        title: get(this.$route.params, "title", null),
        description: get(this.$route.params, "description", null),
        createdBy: get(this.$route.params, "createdBy", null),
        acknowledgementDeadlineDate: get(
          this.$route.params,
          "acknowledgementDeadlineDate",
          null
        ),
        expiryDate: get(this.$route.params, "expiryDate", null),
        files: [],
      },
      error: null,
      isLoading: false,
      acknowledgeModalError: null,
      isSubmittingAcknowledge: false,
      showAcknowledgeModal: false,
    };
  },
  mounted() {
    this.getDetails();
  },
  computed: {
    isAcknowledged() {
      return get(this.itemData, "isAcknowledged", false);
    },
    isExpired() {
      const expiryDate = moment(this.itemData.expiryDate);
      const today = moment(Date.now());
      return expiryDate.isBefore(today, "day");
    },
    acknowledgementStatusVariant() {
      if (this.isAcknowledged) return "success";
      else if (this.isExpired) return "error";
      else {
        return "secondary";
      }
    },
    acknowledgementStatusText() {
      if (this.isAcknowledged) return "Acknowledged";
      else if (this.isExpired) return "Expired";
      else {
        return "Pending Acknowledgement";
      }
    },
  },
  methods: {
    get,
    formatDate(date) {
      if (date !== "-") return displayUtcDate(date);
      else return date;
    },
    async getDetails() {
      this.isLoading = true;
      this.error = null;
      const result = await getAcknowledgementDetails({
        id: get(this.$route.params, "acknowledgementId"),
      });
      if (result.success) {
        this.setFormData(get(result.payload, "model"));
        this.isAcknowledged = true;
      } else {
        this.error = result.payload;
      }
      this.isLoading = false;
    },
    setFormData(data) {
      this.itemData.cpUpdateId = get(data, "cpUpdateDetails.cpUpdateId", "-");
      this.itemData.cpUpdateNo = get(data, "cpUpdateDetails.cpUpdateNo", "-");
      this.itemData.isAcknowledged = get(data, "isAcknowledged", "-");
      this.itemData.dateOfAckNowledgement = get(
        data,
        "dateOfAckNowledgement",
        "-"
      );
      this.itemData.title = get(data, "cpUpdateDetails.title", "-");
      this.itemData.description = get(data, "cpUpdateDetails.description", "-");
      this.itemData.createdBy = get(data, "cpUpdateDetails.createdBy", "-");
      this.itemData.acknowledgementDeadlineDate = get(
        data,
        "cpUpdateDetails.acknowledgementDeadlineDate",
        "-"
      );
      this.itemData.expiryDate = get(data, "cpUpdateDetails.expiryDate", "-");
      let attachments = get(data, "cpUpdateDetails.attachments", []);
      if (attachments.length > 0) this.itemData.files = attachments;
    },
    confirmAcknowledgement() {
      this.toggleAcknowledgeModal(true);
    },
    toggleAcknowledgeModal(value) {
      this.acknowledgeModalError = null;
      this.showAcknowledgeModal = value;
    },
    async onAcknowledge() {
      this.isSubmittingAcknowledge = true;
      this.acknowledgeModalError = null;
      const result = await acknowledgeCPUpdate({
        CPUpdateId: get(this.itemData, "cpUpdateId"),
      });
      if (result.success) {
        this.isSubmittingAcknowledge = false;
        this.toggleAcknowledgeModal(false);
        this.getDetails();
      } else {
        this.isSubmittingAcknowledge = false;
        this.acknowledgeModalError = result.payload;
      }
    },
  },
};
</script>
<style scoped>
:deep .download-btn{
  display: none;
}
</style>